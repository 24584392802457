<template>
  <div class="__dashbody">
    <dashboardNav :haveBanner="false" />
    <div class="section">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3">
                    <div class="leftbar __msgleft">
                        <img src="../assets/img/cross-out.svg" id="closebtn">
                        <!-- <i class="fa fa-times" id="closebtn"></i> -->
                        <div class="__sidep __msgsidp">
                            <div class="form-group">
                                <input type="text" class="form-control __msgserch" placeholder="Search Conversation" id="">
                            </div>

                            <p class="__msgstag">Your interaction will be displayed here</p>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-9">
                    <section>
                        <div class="__house">
                            <div class="__msgous">
                                <div class="__msgstagdiv text-center">
                                    <img src="../assets/img/msgimg.png" class="__msgstagdivimg">
                                    <p class="__msgstagdivp">You have not started  a conversation </p>
                                    <hr class="__msgstagdivhr">
                                    <p class="__msgstagdivp1">To start a conversation, go to an individual profile and click on the <br>messaging icon to start a conversation with the individual</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>			
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapActions } from "vuex";

import dashboardNav from "../components/dashboardNav.vue";
import Loader from "../components/loader";

export default {
  
  components: {
    dashboardNav,
    Loader,
  },

  mounted () {
		$('#openSidebar').click(function(){
        $('.leftbar').addClass('biger');
        });
        $('#closebtn').click(function(){
            $('.leftbar').removeClass('biger');
        });
	}
  
};
</script>

<style lang="scss">

</style>
